<template>
    <div>
        <div class="flex flex-wrap gap-3" v-if="!this.submitted">
            <div class="h-auto min-h-fit rounded-lg p-3  rounded-lg flex-grow">
                <div @drop.prevent="dropFile" @dragenter="dropFile" @dragover="dropFile" :class="{ 'bg-pink-800': dragActive }" style="height:280px" class=" flex items-center justify-center w-full px-12 py-8 border-4 border-gray-300 border-dashed rounded dark:border-2">
                    <div class="flex flex-col items-center justify-center gap-1 w-full text-white dark:text-gray-100 py-2">
                        <p  class="text-base md:text-xl font-semibold text-black">{{translations.pdf_to_jpg_drop}}</p>
                        <p class="text-xs text-black md:text-sm font-semibold dark:text-gray-400">upload text</p>
                        <label  for="file" class="w-30 flex flex-col items-center   bg-red-500 px-5 py-2 rounded-lg shadow-lg   border  cursor-pointer  text-white">
                            <span class="text-white dark:text-gray-100">upload file</span>
                            <!-- {{ translations.select_file }} -->
                            <input  type="file" multiple name="fields" @change="handleFileSelection"  ref="file" accept=".pdf" id="file" class="hidden" />

                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex w-full" v-if="this.files.length && !this.submitted">
            <div class="flex flex-wrap shadow-2xl mt-4 rounded-lg p-4 w-full">
                <h3 class="w-full">Images({{this.files.length}})</h3>
                <div class="w-full p-3 my-1 flex flex-wrap flex-row" v-for="file in files">
                    <div class="w-full flex flex-wrap flex-row justify-between gap-2  border-2 border-gray-200 rounded-md p-2  relative">
                        <div class="w-full flex flex-wrap flex-row justify-between">
                            <div class="flex flex-wrap gap-2">
                                <img :src="file.dataURL" class="rounded-lg my-2 h-5 mx-auto object-contain" style="aspect-ratio: 16/9;object-fit: cover;">
                                <span class="text-xs font-semibold text-red-600 my-2">{{ file.name.substr(file.name.length - 10) }}</span>
                            </div>
                            <div class="flex flex-wrap flex-row gap-2">
                                <div class=""><p class="mt-2">to</p></div>
                                <div>
                                    <select id="converters"  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                        <option selected>...</option>
                                        <option value="Png">Png</option>
                                        <option value="jpeg">jpeg</option>
                                        <option value="Webp">Webp</option>
                                        <option value="Pdf">Pdf</option>
                                    </select>
                                </div>
                            </div>
                            <div class="text-red-800 hover:text-red-500">
                                <span class="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10 my-2">Ready to convert</span>
                            </div>
                            <div class="mt-2">
                                <span class="text-red-600">{{Math.floor(file.file.size/1024)+"KB"}}</span>
                            </div>
                            <div>
                                <button @click="remove(files.indexOf(file))" title="Remove file" class="inline-flex items-center top-1 right-1 my-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 mx-auto text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w-full flex flex-wrap flex-row  justify-between my-2">
                    <div>
                        <label  for="file" class="w-30 flex flex-col items-center my-2 p-2 hover:bg-red-500 bg-red-800 px-5 py-2 rounded-lg shadow-lg border  cursor-pointer text-white">
                            <span class="text-white  text-xl dark:text-gray-100"><i class="fa fa-solid fa-plus"></i> upload file</span>
                            <!-- {{ translations.select_file }} -->
                            <input  type="file" multiple name="fields" @change="handleFileSelection" ref="file" accept=".pdf,.jpg,.jpeg,.png" id="file" class="hidden"/>
                        </label>
                    </div>
                    <div class="">
                        <button @click="uploadFiles" class="w-40 p-2 my-2 text-xl bg-red-800  font-semibold leading-tight text-white  border rounded cursor-pointer  dark:border-none hover:bg-red-500  hover:shadow-sm">
                            Convert
                            <i class="fa-solid fa-arrow-right"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <div class="flex flex-wrap shadow-2xl mt-4 rounded-lg p-4" v-if="this.files.length && this.submitted">
                <h3>Results({{this.files.length}})</h3>
                <div class="flex flex-wrap justify-between w-full p-3 my-1" v-for="(file,index) in files" :key="index">
                    <div class="flex flex-wrap flex-row w-full justify-between  gap-2  border-2 border-gray-200 rounded-md p-2">
                        <div class="flex flex-wrap gap-2">
                            <img :src="file.dataURL" class="rounded-lg h-5 my-1 mx-auto object-contain" style="aspect-ratio:16/9;object-fit: cover;">
                            <span class="text-xs font-semibold text-red-600 my-1">{{ getFileBaseName(file.name)+ '.zip' }}</span>
                        </div>
                        <div class="">
                            <span class="text-red-600">{{Math.floor(file.file.size/1024)+"KB"}}</span>
                        </div>
                        <div>
                            <button @click="downloadJpgs(file)" class="w-full bg-red-800 p-1 mt-1 text-white rounded ">Download</button>
                        </div>

                        <div class="">
                            <button @click="remove(files.indexOf(file))" title="Remove file" class="inline-flex items-center  top-2 right-1">
                                <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 mx-auto text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "vue";
import axios from "axios";
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist/legacy/build/pdf';
import JSZip from 'jszip';


GlobalWorkerOptions.workerSrc = '/node_modules/pdfjs-dist/build/pdf.worker.min.mjs';


export default{
    props: {
        translations:Object,
    },


    setup() {
        const files = ref([]);
        const dragActive = ref(false);
        const submitted = ref(false);

        const handleFileSelection = (event) => {
            const newFiles = Array.from(event.target.files).map((file) => {
                const extension = file.name.split('.').pop().toLowerCase();
                if (extension !== 'pdf') {
                    console.error("Unsupported file type:", extension);
                    return null; // Skip this file
                }

                return {
                    dataURL: URL.createObjectURL(file),
                    name: file.name,
                    uploading: false,
                    uploaded: false,
                    uploadProgress: 0,
                    error: null,
                    file: file,
                };
            }).filter((file) => file !== null);

            files.value.push(...newFiles);
        };



        const uploadFiles = async () => {
            submitted.value=true;
            for (let i = 0;i<files.value.length;i++){
                await uploadFile(files.value[i]);
            }
        };

        const download =async(index) => {
            alert('download')
        };
        const remove =async(index) => {
            files.value.splice(index, 1);
            if (!files.value.length) {
                submitted.value =false;
            }
        };

        const uploadFile = async (file) => {
            file.uploading = true;
            file.uploadProgress = 0;
            file.jpgBlobs = []; // Initialize jpgBlobs array

            const reader = new FileReader();

            reader.onload = async (event) => {
                const pdfData = new Uint8Array(event.target.result);
                const loadingTask = getDocument({ data: pdfData });

                try {
                    const pdf = await loadingTask.promise;
                    const totalPages = pdf.numPages;
                    const jpgBlobs = []; // Array to store JPG blobs for each page

                    for (let pageNum = 1; pageNum <= totalPages; pageNum++) {
                        const page = await pdf.getPage(pageNum);
                        const viewport = page.getViewport({ scale: 2.5 });
                        const canvas = document.createElement('canvas');
                        const context = canvas.getContext('2d');
                        canvas.width = viewport.width;
                        canvas.height = viewport.height;

                        const renderContext = {
                            canvasContext: context,
                            viewport: viewport,
                        };

                        const renderTask = page.render(renderContext);
                        await renderTask.promise;

                        // Convert canvas to JPG blob
                        canvas.toBlob((blob) => {
                            jpgBlobs.push(blob); // Store the JPG blob for this page

                            if (jpgBlobs.length === totalPages) {
                                // All pages rendered and converted
                                // Now you can do something with jpgBlobs array, like uploading or displaying
                                console.log("All pages converted to JPG blobs:", jpgBlobs);
                                file.jpgBlobs = jpgBlobs; // Store the JPG blobs in the file object
                            }
                        }, 'image/jpeg');
                    }
                } catch (error) {
                    console.error("Error loading PDF for conversion:", error);
                    file.uploading = false;
                    file.error = "Error converting PDF";
                }
            };

            reader.readAsArrayBuffer(file.file);
        };

        const downloadJpgs = async (file) => {
            const zip = new JSZip();

            for (let i = 0; i < file.jpgBlobs.length; i++) {
                const jpgFileName = file.name.replace(/\.[^/.]+$/, "") + "_page" + (i + 1) + ".jpg";
                zip.file(jpgFileName, file.jpgBlobs[i]);
            }

            const zipBlob = await zip.generateAsync({ type: 'blob' });
            const downloadLink = document.createElement("a");
            downloadLink.href = URL.createObjectURL(zipBlob);
            downloadLink.download = file.name.replace(/\.[^/.]+$/, "") + "_images.zip";
            downloadLink.click();
        };

        const getFileBaseName = (fileName) => {
            const nameParts = fileName.split('.');
            // Return the first part of the split array, which is the base name without extension
            return nameParts[0];
        };


        const uploadFileOld = async(file) => {
            const formData = new FormData();
            formData.append("convert", 'to-pdf');
            formData.append("images",file.file); // Append the actual File object

            const config={
                headers:{"Content-Type":"multipart/form-data"},
                onUploadProgress:(progressEvent) =>{
                    const progress = Math.round(
                        (progressEvent.loaded/progressEvent.total) * 100
                    );
                    file.uploading=true;
                    file.uploadProgress = progress;
                },
            };

            try {
                // Replace 'your-api-endpoint' with your actual server API endpoint
                const response = await axios.post("/file-upload-pdf",formData, config);
                file.uploading = false;
                file.uploaded = true;
                file.uploadProgress=100;
                file.responseData=response.data;
                file.downloadPath = response.data.path;
            } catch (error){
                file.uploading = false;
                file.error = "Error uploading image";
                console.error("Error uploading image:", error);
            }
        };
        const dragover = async () => {
            dragActive.value = true;
        };
        const dragleave = async (event) => {
            dragActive.value = false;
        };
        const dropFile = (event) => {
            event.preventDefault();
            dragActive.value=false;
            const newFiles = Array.from(event.dataTransfer.files).map((file) => ({
                dataURL:URL.createObjectURL(file),
                name:file.name,
                uploading: false,
                uploaded: false,
                uploadProgress: 0,
                error: null,
                file: file, // Store the actual File object
            }));

            files.value.push(...newFiles);
        };
        return {
            dragover,
            dragleave,
            dropFile,
            remove,
            dragActive,
            submitted,
            files,
            handleFileSelection,
            uploadFiles,
            downloadJpgs,
            getFileBaseName
        };
    },
};

</script>
<style scoped>
.flex-grow {
    flex-grow: 1;
}
</style>
